<template>
  <div>
    <div class="text-center mt-6" v-if="!dataLoaded">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>

    <div v-show="dataLoaded">
      <v-row class="my-0">
        <v-col cols="12" class="py-0">
          <v-btn
            small
            color="primary"
            class="white--text px-4"
            @click="dialog = true"
          >
            <v-icon left small>mdi-plus</v-icon>Добавить
          </v-btn>

          <v-card class="table-card mt-4">
            <v-data-table
              ref="uploadingParametersTable"
              class="base-table"
              fixed-header
              hide-default-footer
              :headers="headers"
              :items="uploadingParameters.list"
              :items-per-page="-1"
            >
              <!-- custom table header columns -->
              <template #header.fromDuration="{ header }">
                <span v-html="header.text"></span>
              </template>

              <template #header.toDuration="{ header }">
                <span v-html="header.text"></span>
              </template>

              <template #header.fromListenerCount="{ header }">
                <span v-html="header.text"></span>
              </template>

              <template #header.toListenerCount="{ header }">
                <span v-html="header.text"></span>
              </template>

              <template #header.dayCount="{ header }">
                <span v-html="header.text"></span>
              </template>

              <!-- custom table body columns -->
              <template #item.fromDuration="{ item }">
                <span>{{ item.fromDuration || '-' }}</span>
              </template>

              <template #item.toDuration="{ item }">
                <span>{{ item.toDuration || '-' }}</span>
              </template>

              <template #item.fromListenerCount="{ item }">
                <span>{{ item.fromListenerCount || '-' }}</span>
              </template>

              <template #item.toListenerCount="{ item }">
                <span>{{ item.toListenerCount || '-' }}</span>
              </template>

              <template #item.actions="{ item }">
                <v-icon
                  color="lightGrey"
                  class="mr-1 edit-btn"
                  size="22"
                  title='Редактировать'
                  @click="editUploadingParameter(item)"
                >
                  mdi-pencil-outline
                </v-icon>

                <v-icon
                  color="lightGrey"
                  class="remove-btn"
                  size="22"
                  title="Удалить"
                  @click="confirmRemoveTheme(item)"
                >
                  mdi-trash-can-outline
                </v-icon>
              </template>

              <template slot="no-data">
                <div>Таблица пуста</div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <UploadingParameterDialog
        :dialog="dialog"
        :uploadingParameter="selectedItem"
        :loading="loading"
        @saveUploadingParameter="saveUploadingParameter"
        @closeDialog="closeDialog"
      />

      <DeleteConfirmDialog
        :dialog="confirmDialog"
        :loading="deleteLoading"
        :message="'Вы уверены, что хотите удалить период выгрузки?'"
        @confirm="removeUploadingParameter"
        @closeConfirmModal="closeConfirmDialog"
      />
    </div>
  </div>
</template>

<script>
import { integrationApi } from '@/api'
import { mapGetters } from 'vuex'
import { UploadingParameters } from '@/models'
import UploadingParameterDialog from '@/components/settings/uploadingParameters/dialogs/UploadingParameter'
import DeleteConfirmDialog from '@/components/dialogs/DeleteConfirm'

export default {
  name: 'UploadingParameters',

  metaInfo: {
    title: 'Управление периодом выгрузки'
  },

  components: {
    UploadingParameterDialog,
    DeleteConfirmDialog
  },

  async created() {
    await this.getUploadingParameters()
    this.dataLoaded = true
  },

  mounted() {
    this.hideHtmlOverflow()
    this.setTableHeight()
    window.addEventListener("resize", this.setTableHeight)
  },

  destroyed() {
    this.showHtmlOverflow()
    window.removeEventListener("resize", this.setTableHeight)
  },

  data: () => ({
    dataLoaded: false,
    headers: [
      { text: 'Длительность<br>цикла от', value: 'fromDuration', sortable: false, align: 'left' },
      { text: 'Длительность<br>цикла до', value: 'toDuration', sortable: false, align: 'left' },
      { text: 'Количество<br>слушателей от',value: 'fromListenerCount', sortable: false, align: 'left' },
      { text: 'Количество<br>слушателей до',value: 'toListenerCount', sortable: false, align: 'left' },
      { text: 'Разрешенный<br>период выгрузки',value: 'dayCount', sortable: false, align: 'left' },
      { text: 'Действия', value: 'actions', sortable: false, align: 'right', width: '110px' }
    ],
    dialog: false,
    confirmDialog: false,
    loading: false,
    deleteLoading: false,
    selectedItem: null,

    uploadingParameters: new UploadingParameters()
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight'])
  },

  methods: {
    async getUploadingParameters() {
      try {
        const data = await integrationApi.getUploadingParameters()
        data.length && this.uploadingParameters.buildFromAPI(data)
      } catch (e) {
        console.log(e.status)
      }
    },

    async saveUploadingParameter(uploadingParameter) {
      try {
        this.loading = true
        let data

        if (uploadingParameter.id) {
          data = await integrationApi.updateUploadingParameter(uploadingParameter.getForAPI())
          this.uploadingParameters.updateUploadingParameter(data)
        } else {
          data = await integrationApi.saveUploadingParameter(uploadingParameter.getForAPI())
          this.uploadingParameters.addUploadingParameter(data)
        }

        this.closeDialog()
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения периода выгрузки')
      } finally {
        this.loading = false
      }
    },

    async removeUploadingParameter() {
      try {
        if (this.selectedItem.id) {
          this.deleteLoading = true

          await integrationApi.removeUploadingParameter(this.selectedItem.id)
          this.uploadingParameters.removeUploadingParameter(this.selectedItem.id)
          this.closeConfirmDialog()
        }
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка удаления периода выгрузки')
      } finally {
        this.deleteLoading = false
      }
    },

    editUploadingParameter(item) {
      this.selectedItem = item
      this.dialog = true
    },

    confirmRemoveTheme(item) {
      this.selectedItem = item
      this.confirmDialog = true
    },

    closeDialog() {
      this.dialog = false
      this.selectedItem = null
    },

    closeConfirmDialog() {
      this.confirmDialog = false
      this.selectedItem = null
    },

    setTableHeight() {
      // суммарная высота элементов на странице, кроме таблицы
      const otherElements = 321 + this.techMessageHeight

      if (this.$refs?.uploadingParametersTable) {
        this.$refs.uploadingParametersTable.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
      }
    },

    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>