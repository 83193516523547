<template>
	<v-row justify="center">
		<v-dialog :value="dialog" max-width="600" @input="closeDialog">
			<v-card class="dialog-card">
				<v-card-title class="headline accent--text pb-4">
          {{ isEdit ? 'Редактирование периода выгрузки' : 'Добавление периода выгрузки' }}
        </v-card-title>

				<v-card-text outlined tile class="border-top">
					<v-container>
						<v-row>
							<v-col cols="6" class="pb-0 pt-5 pl-0">
                <v-text-field
                  v-model="uploadingParameterLocal.fromDuration"
                  label="Длительность цикла от"
                  placeholder="0-999"
                  v-mask="'###'"
                  :error-messages="errorMessages.fromDuration"
                  @input="$v.uploadingParameterLocal.fromDuration.$touch()"
                  @blur="$v.uploadingParameterLocal.fromDuration.$touch()"
                />
              </v-col>

              <v-col cols="6" class="pb-0 pt-5 pr-0">
                <v-text-field
                  v-model="uploadingParameterLocal.toDuration"
                  label="Длительность цикла до"
                  placeholder="0-5000"
                  v-mask="'####'"
                  :error-messages="errorMessages.toDuration"
                  @input="$v.uploadingParameterLocal.toDuration.$touch()"
                  @blur="$v.uploadingParameterLocal.toDuration.$touch()"
                />
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="6" class="pb-0 pl-0">
                <v-text-field
                  v-model="uploadingParameterLocal.fromListenerCount"
                  label="Количество слушателей от"
                  placeholder="0-9999"
                  v-mask="'####'"
                  :error-messages="errorMessages.fromListenerCount"
                  @input="$v.uploadingParameterLocal.fromListenerCount.$touch()"
                  @blur="$v.uploadingParameterLocal.fromListenerCount.$touch()"
                />
              </v-col>

              <v-col cols="6" class="pb-0 pr-0">
                <v-text-field
                  v-model="uploadingParameterLocal.toListenerCount"
                  label="Количество слушателей до"
                  placeholder="0-9999"
                  v-mask="'####'"
                  :error-messages="errorMessages.toListenerCount"
                  @input="$v.uploadingParameterLocal.toListenerCount.$touch()"
                  @blur="$v.uploadingParameterLocal.toListenerCount.$touch()"
                />
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="12" class="pb-0 px-0">
                <v-text-field
                  v-model="uploadingParameterLocal.dayCount"
                  label="Разрешенный период выгрузки"
                  placeholder="0-99"
                  v-mask="'##'"
                  :error-messages="errorMessages.dayCount"
                  @input="$v.uploadingParameterLocal.dayCount.$touch()"
                  @blur="$v.uploadingParameterLocal.dayCount.$touch()"
                />
              </v-col>
            </v-row>
					</v-container>
				</v-card-text>				

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">Отмена</v-btn>

          <v-btn
            :disabled="disableSaveBtn"
						:loading="loading"
            color="success"
            text
            @click="saveUploadingParameter"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import { UploadingParameter } from '@/models'
import { setValidateMessages, regexFrom0To99, regexFrom0To999, regexFrom0To9999, regexFrom0To5000 } from '@/scripts/validation'

export default {
  name: 'UploadingParameterDialog',

	props: {
		dialog: Boolean,
    uploadingParameter: Object,
		loading: Boolean
	},

	data: () => ({
    uploadingParameterLocal: new UploadingParameter()
	}),

  validations() {
    return {
      uploadingParameterLocal: {
        fromDuration: { regexFrom0To999 },
        toDuration: { regexFrom0To5000 },
        fromListenerCount: { regexFrom0To9999 },
        toListenerCount: { regexFrom0To9999 },
        dayCount: { regexFrom0To99 }
      }
    }
  },

	computed: {
    isEdit() {
      return this.uploadingParameter?.id
    },

    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.uploadingParameterLocal.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.uploadingParameterLocal[key])
      })

      return allErrors
    },

    disableSaveBtn() {
      const params = this.uploadingParameterLocal

      const isLine1Valid = params.fromDuration && params.toDuration
      const isLine2Valid = params.fromListenerCount && params.toListenerCount

      return !((isLine1Valid || isLine2Valid) && params.dayCount) || this.$v.$invalid
    }
	},

	methods: {
		saveUploadingParameter() {
      this.$emit('saveUploadingParameter', _cloneDeep(this.uploadingParameterLocal))
    },

    resetModal() {
      this.$v.$reset()
      this.uploadingParameterLocal = new UploadingParameter()
    },

    closeDialog() {
      this.resetModal()
			this.$emit('closeDialog')
		}
	},

  watch: {
    uploadingParameter(val) {
      this.uploadingParameterLocal = val ? UploadingParameter.buildFromAPI(val) : new UploadingParameter()
    },

    dialog(val) {
      !val && this.resetModal()
    }
  }
}
</script>